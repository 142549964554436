import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const Essentials = lazy(() => import("./pages/shop/CategoryShop"));
const Boys = lazy(() => import("./pages/shop/CategoryShop"));
const Girls = lazy(() => import("./pages/shop/CategoryShop"));
const CategoryShop = lazy(() => import("./pages/shop/CategoryShop"));
const Shop = lazy(() => import("./pages/shop/Shop"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

//policy pages
const PrivacyPolicy = lazy(() => import("./pages/policy/privacy-policy"));
const TermsOfUse = lazy(() => import("./pages/policy/terms-of-use"));
const RefundsReturnPolicy = lazy(() =>
  import("./pages/policy/refunds-return-policy")
);

const ShippingPolicy = lazy(() => import("./pages/policy/shipping-policy"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Login = lazy(() => import("./pages/other/Login"));
const ForgetPassword = lazy(() =>
  import("./pages/authenticate/ForgetPassword")
);

const Register = lazy(() => import("./pages/other/Register"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Success = lazy(() => import("./pages/payment/Success"));
const Failure = lazy(() => import("./pages/payment/Failure"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Orders = lazy(() => import("./pages/order/Orders"));
const OrderView = lazy(() => import("./pages/order/OrderView"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    // document.oncontextmenu = new Function("return false;");
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Shop pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/category/:cat_title"}
                  render={(routeProps) => (
                    <CategoryShop
                      {...routeProps}
                      key={routeProps.match.params.cat_title}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={Shop}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/search/:s_cont"}
                  render={(routeProps) => (
                    <Shop
                      {...routeProps}
                      key={routeProps.match.params.s_cont}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/essentials"}
                  component={Essentials}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/boys"}
                  component={Boys}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/girls"}
                  component={Girls}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/blogs/:blogid"}
                  render={(routeProps) => (
                    <BlogDetailsStandard
                      {...routeProps}
                      key={routeProps.match.params.blogid}
                    />
                  )}
                />
                {/* Policy pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shipping-policy"}
                  component={ShippingPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/terms-of-use"}
                  component={TermsOfUse}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/refunds-return-policy"}
                  component={RefundsReturnPolicy}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dashboard/:page"}
                  render={(routeProps) => (
                    <Dashboard
                      {...routeProps}
                      key={routeProps.match.params.page}
                    />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dashboard"}
                  component={Dashboard}
                />
                
                <Route
                  path={process.env.PUBLIC_URL + "/orders/view/:order_id"}
                  render={(routeProps) => (
                    <OrderView
                      {...routeProps}
                      key={routeProps.match.params.order_id}
                    />
                  )}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/orders"}
                  component={Orders}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={Login}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/register"}
                  component={Register}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order/success"}
                  component={Success}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order/failure"}
                  component={Failure}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
