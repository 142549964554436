import { LOGIN,LOGOUT } from "../actions/authAction";

const initState = {
    userStatus:false,
    username:"",
    phone:"",
    token:""

}

export const AuthReducer =(state=initState,action) =>{
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          userStatus: true,
          username: action.payload.username,
          phone: action.payload.phone,
          token: action.payload.token,
        };
      case LOGOUT:
        return {
          ...state,
          userStatus: false,
          username: "",
          phone: "",
          token: "",
        };
      default:
        return {
          ...state,
        };
    }
}





