export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";


export const login = (status) =>{
    return {
      type: LOGIN,
      payload: {...status},
    };
}

export const logout = () => {
  return {
    type: LOGOUT,
  };
};